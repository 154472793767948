import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { TypeSafeMatCellDefModule } from 'src/components/type-safe-mat-cell-def/type-safe-mat-cell-def.module';
import { DocumentTagsListComponent } from './document-tags-list.component';

@NgModule({
  declarations: [DocumentTagsListComponent],
  exports: [DocumentTagsListComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    ScrollingModule,
    TableVirtualScrollModule,
    TypeSafeMatCellDefModule,
  ]
})
export class DocumentTagsListModule { }
