import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import { DocumentOverride } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import * as DocumentOverrideActions from './document-overrides.actions'

export const documentOverridesFeatureKey = 'documentOverrides'
export interface DocumentOverridesState extends EntityState<DeepReadonly<DocumentOverride>> {
}

export const selectId = (documentOverride: DocumentOverride) => documentOverride.override_id
export const adapter = createEntityAdapter<DeepReadonly<DocumentOverride>>({ selectId })

const initialState: DocumentOverridesState = adapter.getInitialState({
})

export const documentOverridesReducer = createReducer(
  initialState,
  on(DocumentOverrideActions.loadDocumentOverridesSuccess,
    (state, action) =>
      adapter.upsertMany(action.documentOverrides, state)),
  on(DocumentOverrideActions.createDocumentOverrideSuccess,
    (state, action) =>
      adapter.addOne(action.override, state)),
  on(DocumentOverrideActions.updateDocumentOverrideSuccess,
    (state, action) =>
      adapter.updateOne({ id: action.override.override_id, changes: action.override }, state)),
  on(DocumentOverrideActions.deleteDocumentOverrideSuccess,
    (state, action) =>
      adapter.removeOne(action.id, state)),
)

